import sanitizeHtml, { defaults } from "sanitize-html";

export const sanitize = (dirty: string) => {
  return sanitizeHtml(dirty, {
    allowedTags: [],
  });
};

export const sanitizeWYSIWYG = (dirty: string) =>
  sanitizeHtml(dirty, {
    allowedTags: [...defaults.allowedTags, "img"],
  });
